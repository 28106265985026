/* You can add global styles to this file, and also import other style files */
:root {
    --accent-fill-rest: white;
}

html * {
    font-family: Arial, Helvetica, sans-serif;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    background-color: var(--theme-background-color);
}

.margin-container {
    margin: 8px;
}

.loading-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    height: 100px;
    width: 100px;
}

.center {
    justify-content: center;
    align-items: center;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

/*Custom scrollbar for the call history*/
.scrollable::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
}

.scrollable::-webkit-scrollbar {
    width: 10px;
}

.scrollable::-webkit-scrollbar-thumb {
    background: rgba(170, 170, 170, .5);
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background: #a5a5a5;
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
}

.shorten {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

/*Override defaults from FluentUI*/
fluent-text-field.outline::after {
    border-bottom: calc(var(--focus-stroke-width) * 1px) solid var(--theme-accent-background);
}

fluent-tabs::part(activeIndicator) {
    background: var(--theme-accent-background);
    width: 40px;
}

.tooltip-container {
    text-align: center;
    z-index: 100;
    position: fixed;
    padding: 6px 12px;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: initial;
    color: white;
    width: auto;
    background: #111111ee;
    border-radius: 4px;
    box-sizing: border-box;
    opacity: 0;
    transform: translate(-50%, -30%);
    animation: tooltip-slide 0.18s ease-out 0.5s;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

a {
    color: var(--neutral-foreground-rest);
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    color: var(--theme-accent-background);
    text-decoration: underline;
}